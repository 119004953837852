<template>
  <el-dialog 
    :title="itemId ? '修改':'新增'"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="120px" size="mini" v-loading="loading">
        <el-form-item prop="name" label="姓名" verify>
          <el-input v-model="formData.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item prop="userName" label="账号名称" verify>
          <el-input v-model="formData.userName" placeholder="请输入账号名称"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码" verify>
          <el-input v-model="formData.password" placeholder="请输入密码" show-password></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机号" verify>
          <el-input v-model="formData.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item prop="userType" label="状态" verify>
          <gl-option v-model="formData.userType" :list="authList"></gl-option>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import base64 from '@/utils/base64'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data(){
    return{
      oldList: [],
      formData: {},
      loading: false,
      authList: [
        {name: '管理员', value: 'admin'},
        {name: '用户', value: 'user'},
      ]
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getAccount()
      }
    },
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        console.log('formData:', this.formData)
        if(!valid) return false
        this.loading = true
        const password = base64.base64Encode(this.formData.password) // window.btoa(this.formData)
        let params = {
          ...this.formData,
          password,
        }
        return 
        let res = null
        if(this.itemId){
          res = await updataUser(params)
        }else{
          res = await addUser(params)
        }
        this.loading = false
        this.$message.success('操作成功')
        this.$emit('reload')
        this.handleClose()
      })
    },
    getAccount(){
      this.loading = true
      setTimeout(() => {
        this.formData = {
          name: '姓名',
          userName: '账号昵称',
          password: 'admin',
          phone: '18680283361',
          userType: '2',
        }
        this.loading = false
      }, 1000)
        
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style>

</style>