<template>
  <div>
    <gl-title title="账号管理"></gl-title>
    <div style="margin: 18px;">
      <el-button type="primary" size="mini" @click="mClickAction('mUpdateVisible')"> 新增 </el-button>
    </div>
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="name" label="姓名">
          <gl-list-search v-model="mFormData.name" placeholder="姓名"></gl-list-search>
        </el-form-item>
        <el-form-item prop="userName" label="账号昵称">
          <gl-list-search v-model="mFormData.userName" placeholder="账号昵称"></gl-list-search>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" min-width="30"></el-table-column>
        <el-table-column prop="userName" label="姓名" show-overflow-tooltip></el-table-column>
        <el-table-column prop="userName" label="账号昵称"></el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="permissionGroup" label="权限组"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="mClickAction('mUpdateVisible',scope.row.id)"> 修改 </el-button>
            <el-button type="text" @click="mClickAction('mPreviewVisible', scope.row.id)"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <update :visible.sync="mUpdateVisible" :itemId="mItemId"/>
    <preview :visible.sync="mPreviewVisible" :itemId="mItemId"/>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import update from './update.vue'
import preview from './preview.vue'
export default {
  components: {update,preview},
  mixins: [ListMixin],
  mounted(){
    this.mGetListFun 
    this.mGetList()
  },
}
</script>

<style>

</style>